<template>
  <div class="staff-form" style="position:relative;width: calc(100vw);height: calc(100vh - 110px);padding-bottom: 60px;overflow-y: scroll;">

    <van-form @submit="onSubmit" @failed="failed" :label-width="labelWidth" ref="checkForm">
      <div class="form-item">
<!--        <span class="label-tips">*</span>-->
        <van-field
            v-model="formData.infoName"
            readonly
            name="infoName"
            label="企业名称"
            placeholder="企业名称"
            :rules="[{ required: true, message: '必填'}]"
        />
      </div>
      <div class="form-item">
        <span class="label-tips">*</span>
        <van-field
            v-model="formData.name"
            name="name"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '必填'}]"
        />
      </div>

      <div class="form-item">
        <span class="label-tips">*</span>
        <van-field
            v-model="formData.id"
            :readonly="readonly"
            name="sentry"
            maxlength="18"
            label="身份证号"
            placeholder="身份证号"
            :rules="[{ required: true, message: ''},
            { validator:validatorId, message: '请输入正确的身份证号'}
            ]"
        />
      </div>
      <div class="form-item">
          <van-field
              readonly
              clickable
              name="phone"
              label="手机号"
              placeholder="手机号"
              :value="formData.phone"
              :rules="[{ validator:validatorPhone,required: false, message: '填入正确的手机号'}]"
              @touchstart.native.stop="phoneShow = true"
          />
          <van-number-keyboard
              v-model="formData.phone"
              :show="phoneShow"
              :maxlength="11"
              @blur="phoneShow = false"
          />
      </div>
      <div class="form-item">

        <van-field
            v-model="formData.sentry"
            name="sentry"
            label="岗位"
            placeholder="用户名"
            :rules="[{ required: false, message: '必填'}]"
        />
      </div>
      <div class="form-item">
        <span class="label-tips">*</span>
        <van-field name="formData.sex" label="性别">
          <template #input>
            <van-radio-group v-model="formData.sex" direction="horizontal" >
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </div>
      <div class="form-item">
        <van-field
            v-model="formData.vaccine1Date"
            is-link
            readonly
            name="vaccine1Date"
            label="第一针日期"
            placeholder="第一针日期"
            @click="openCalendar('vaccine1Date')"
        />
        <van-calendar v-model="showCalendar" @confirm="onConfirm" :min-date="minDate" />
      </div>
      <div class="form-item">
        <van-field
            v-model="formData.vaccine2Date"
            is-link
            readonly
            name="vaccine1Date"
            label="第二针日期"
            placeholder="第二针日期"
            @click="openCalendar('vaccine2Date')"
        />
      </div>
      <div class="form-item">
        <van-field
            v-model="formData.vaccine3Date"
            is-link
            readonly
            name="vaccine1Date"
            label="加强针日期"
            placeholder="加强针日期"
            @click="openCalendar('vaccine3Date')"
        />
      </div>
      <div style="position:fixed;bottom:0px;left:0px;width: calc(100vw);">
        <van-row  type="flex" justify="center"  gutter="0">
          <van-col :span="24">
            <van-button size="large" type="primary" :loading="loading" native-type="submit">提交</van-button>
          </van-col>
        </van-row>

      </div>
    </van-form>
    <van-dialog v-model="show" title="确认提交？" show-cancel-button @cancel="cancleConfirm" @confirm="confirm">
      <van-notice-bar
          v-if="noticeShow"
          :scrollable="false"
          :text="des"
      />
    </van-dialog>
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }">
      <div>
        <div class="model-title">
          <van-icon name="arrow-left" size="28" />
        </div>
        <div class="success-icon">
          <van-icon name="passed" color="#00E68F" size="40" />
        </div>
        <div class="success-content">提交成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
        </div>
      </div>
    </van-popup>

  </div>
</template>

<script>

import {
  Toast,Button, Col, Row,Image,Icon,RadioGroup, Radio,Form,Field,Notify,Dialog,NoticeBar,Popup,Uploader,NumberKeyboard,Calendar
} from 'vant';
export default {
  components:{
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component,
    [Uploader.name]: Uploader,
    [NumberKeyboard.name]: NumberKeyboard,
    [Calendar.name]: Calendar,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props:{
    isShowData:{
      type:Object,
      default: ()=> {
        return {}
      }
    },
  },
  watch: {
    /*监听主题变化*/
    isShowData:{
      handler(n, o) {
        console.log("change",n)
        this.readonly = false;
        if(n){
          this.formData = n;
          if(n.id){
            // this.readonly = true;
          }else {
            this.resetFormData();
          }
        }else {
          this.resetFormData();
        }

      },
      deep: true,
      immediate:true
    }
  },
  data(){
    return {
      calendarKey:"",
      readonly:false,
      signShow:false,
      checkedSigned:false,
      labelWidth:80,
      formData:{
        infoId:localStorage.getItem("wljptinfoId"),
        infoName:localStorage.getItem("wljptinfoName"),
        createBy:localStorage.getItem("wljptuser"),
        sex:undefined,//全体员工
        name:undefined,//核酸人数
        phone:undefined,//未接种疫苗
        sentry:undefined,//接种一针
        vaccine1Date:undefined,//接种完成
        vaccine2Date:undefined,//加强针
        vaccine3Date:""
      },
      loading:false,
      noticeShow:false,
      phoneShow:false,
      show:false,
      successPop:false,
      showCalendar:false,
      des:"",
      seconds:5,
      timer:null,
      timeOuter:null,
      minDate:new Date(2020,1,1),
    }
  },
  methods:{
    goToMap(){
      this.successPop = false;
      clearTimeout(this.timeOuter);
      this.resetFormData();
      this.$emit("closeReport")
    },
    resetFormData(){
      console.log(localStorage,"formdata localstorage")
       this.formData = {
         infoId:localStorage.getItem("wljptinfoId"),
         infoName:localStorage.getItem("wljptinfoName"),
         createBy:localStorage.getItem("wljptuser"),
         sex:undefined,//全体员工
         name:undefined,//核酸人数
         phone:undefined,//未接种疫苗
         sentry:undefined,//接种一针
         vaccine1Date:undefined,//接种完成
         vaccine2Date:undefined,//加强针
         vaccine3Date:""
       }
    },
    cancleConfirm(){
      this.show = false;
      this.loading = false;
    },
    confirm(){
      console.log(this.formData,"formdata")
      let _this = this;
      this.$http({
        url: "/zuul/cultural/staffVacci",
        method: "post",
        data:this.formData
      }).then(res=>{
        if(res.data.success){
          this.successPop = true;
          this.seconds = 5;
          _this.timer = setInterval(() => {
            _this.seconds--;
            if(_this.seconds<0){
              _this.successPop = false;
              window.clearInterval(_this.timer);
            }
          }, 1000);
          _this.timeOuter = setTimeout(function(){
            _this.resetFormData();
            _this.$emit("closeReport")
          },5000);

        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
        this.loading = false;
      })
    },
    onSubmit(){
      this.loading = true;
      this.show = true;
    },
    failed(rule){
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    validatorPhone(val,rule){
      if(!(/^1[3456789]\d{9}$/.test(val))){
        return false;
      }
      return true;
    },
    validatorId(val,rule){
      var reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
      return reg.test(val);
    },
    openCalendar(key){
      this.calendarKey = key;
      this.showCalendar = true;
    },
    onConfirm(date){
      console.log(date,"date");
      let month = date.getMonth() + 1;
      if(month<10){
        month = "0" + month;
      }
      let d = date.getDate();
      if(d<10){
        d  = "0" + d;
      }
      this.formData[this.calendarKey] = date.getFullYear() + "-" + month + "-" + d;
      this.showCalendar = false;
    }
  },
  mounted(){

    console.log(localStorage,"localStorage")
    this.formData.infoId = localStorage.getItem("wljptinfoId");
    this.formData.infoName = localStorage.getItem("wljptinfoName");
    this.formData.createBy = localStorage.getItem("wljptuser");

  },
  beforeDestroy() {
    if(this.timer){
      window.clearInterval(this.timer);
    }
  },
}
</script>

<style scoped lang="less">
.staff-form /deep/ .van-field__value{
  padding-left: 8px;
}
/deep/ .van-field__error-message{
  top: 16px !important;
  left: 9px !important;
}
.form-title{
  color: rgba(69, 90, 100, 0.6);
  line-height: 45px;
  padding: 5px 16px 0px;
  background: #f7f8fa;
}
.form-item{
  position: relative;
  padding: 0px 16px;

  .label-tips{
    position: absolute;
    color: red;
    top: 16px;
    left: 24px;
    z-index: 1;
  }
}
.item-unit{
  color: #969799;
  font-size: 13px;
  position: absolute;
  top: 15px;
  right: 10px;
  z-index: 1;
}
.confirm-item{
  padding: 5px 20px;
  color: #323233;
}
.model-title{
  letter-spacing: 2px;
  font-weight: 800;
  text-align: center;
  line-height: 50px;
  position: relative;
  .van-icon{
    position: absolute;
    left: 5px;
    top: 10px;
    font-weight: normal;
    color: #969799;
  }
}
.success-icon{
  text-align: center;
  position: relative;
  top: 120px;
}
.success-content{
  padding: 150px 20px;
  text-align: center;
}

/deep/ .form-image .van-field__error-message{
  top: 60px;
  left:90px;
}
/deep/ .van-cell__title{
  padding-left: 8px;
}
/deep/ .van-radio-group--horizontal{
  width: 150px;
}
/deep/ .van-field__error-message{
  position: absolute;
  top: 1px;
  left: -23px;
}
/deep/ .van-dialog{
  width: calc(100vw - 20px);
}
/deep/ .van-dialog__header{
  padding-bottom: 10px;
}
/deep/ .van-dialog__footer{
  padding: 10px 0px;
}
</style>
