<template>
  <div class="staff-list">
    <div class="staff-add" title="新增" @click="report">
      <van-icon name="add-o" color="#1989fa"  size="2.6rem"/>
    </div>
    <h3 class="model-title">疫苗接种情况</h3>

    <div class="des">共{{total}}条记录</div>
    <div style="padding-bottom: 50px;">
      <van-list
          :immediate-check="false"
          v-model="loading"
          :error.sync="error"
          error-text="请求失败，点击重新加载"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad1"
      >
        <div class="check-list" v-for="(item,index) in list" :key="index" >
          <div class="item-choose">
            <span style="position:absolute;left: 22px;top: -5px;">
              {{index+1}}
            </span>
          </div>

          <div class="item-info">
            {{item.name?item.name:item.infoName}}
          </div>
          <div class="item-sex">
            {{item.sex?item.sex:"未知"}}
          </div>
          <div class="item-tip">
            <span v-if="item.needStrengthen=='是'" style="color: red;">第三针超期</span>
            <span v-else>--</span>
          </div>
          <div class="item-check" @click="viewDetail(item)">
<!--            <div class="item-user"><van-icon name="user-o" size="14" />{{item.user?item.user.name:"&#45;&#45;"}}</div>-->
            <div style="font-size: 0.6rem;">{{item.createTime?item.createTime.substr(0,16):"--"}}</div>
          </div>

        </div>

      </van-list>
    </div>
    <div class="list-btn">
      <van-row>
        <van-col span="12">
          <van-uploader :upload-text="'上传excel'" accept=".xlsx,.xls,.csv" :after-read="sendFile" max-count="1" >
            <van-button size="large" type="info" :loading="uploadloading">上传</van-button>
          </van-uploader>
<!--          <van-button size="large" type="primary" @click="upLoad">上传</van-button>-->
        </van-col>
        <van-col span="12">
          <van-button  size="large" type="primary" @click="downLoad">下载</van-button>
        </van-col>

      </van-row>
    </div>
    <!--    检查项弹窗-->
    <van-popup v-model="reportPopup" position="right" :style="{ height: '100%',width:'100%' }"  get-container="#app" >
      <div class="model-title" style="line-height: 40px;font-size: 17px;padding-top: 15px;background: #f7f8fa;"><van-icon name="arrow-left" size="28" @click="reportPopup=false" />&nbsp;&nbsp;
        <span style="position:relative;top: -5px;">新冠疫苗接种情况</span>
      </div>
      <report :isShowData="recordData"  ref="report" @closeReport="closeReport" @viewDetail="viewDetail"></report>
    </van-popup>
  </div>
</template>

<script>
import {
  List,Cell,Col, Row,Icon,Popup,Checkbox,Button,Notify,Tab, Tabs,Calendar,Uploader
} from 'vant';
import report from "./staff"
export default {
  components:{report,
    [Calendar.name]: Calendar,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Button.name]: Button,
    [List.name]: List,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [Cell.name]: Cell,
    [Checkbox.name]: Checkbox,
    [Notify.name]: Notify,
    [Uploader.name]: Uploader,
    [Icon .name]: Icon
  },
  props:{

  },
  watch:{

  },
  data(){
    return {
      checked:{
      },
      loading:false,
      list:[],
      total:0,
      finished:false,
      error:false,
      refreshing:false,
      dict:{},
      recordPopup:false,
      recordData:{},
      defaultGroup:GlobalConfig.infoGroup,
      params:{
        startTime:"",
        endTime:"",
        size: 8,
        page: 0,
        createBy:"",
        // infoId:localStorage.getItem("wljpttagIds")==GlobalConfig.infoGroup?localStorage.getItem("wljptinfoId"):"",
      },
      minDate:new Date(2021,9,1),
      // 整改窗
      reportPopup:false,
      makerDetail:{
        checkType:'1',
        id:localStorage.getItem("wljptinfoId"),
        name:localStorage.getItem("wljptinfoName"),
        // id:GlobalConfig.dev?GlobalConfig.infoId:"",
      },
      calendarShow:false,
      uploadloading:false,
    }
  },
  methods: {
    // 获取需要整改检查记录
    getList() {
      this.finished = false;
      this.loading = true;
      let _this = this;
      this.list = [];
      this.$http({
        url: "/zuul/cultural/staffVacci",
        method: "get",
        params: this.params
      }).then(res => {
        // console.log(res)
        if (res.data.success && res.data.data) {
          _this.list = res.data.data.results;
          _this.total = res.data.data.total;
          _this.list.map(item=>{
            _this.$set(this.checked,item.id,false);
          })
        }
        if(_this.list.length<this.total){
          this.params.page++;
        }
        this.isFinished();
      }).finally(response => {
        this.loading = false;
      })
    },
    viewDetail(item){
      this.recordData = item;
      this.reportPopup = true;
      console.log(item,"item");
     if(item.id){
       this.$http({
         url: "/zuul/cultural/staffVacci/" + item.id,
         method: "get"
       }).then(res=>{
         console.log(res);
         if(res.data.success){
           this.recordData = res.data.data;
         }
       })
     }
    },
    isFinished(){
      if(this.list.length>=this.total){
        this.finished = true;
      }
    },
    onLoad1(){
      let _this = this;
      Promise.all([this.getOnLoadList()]).then(res=>{
        if(res[0].data.success && res[0].data.data.results){
          if(this.params.page>0){
            _this.list = _this.list.concat(res[0].data.data.results)
          }else{
            _this.list = res[0].data.data.results;
          }
          if(_this.list.length>= _this.total){
            _this.finished = true;
          }else{
            _this.params.page++;
          }
        }
      })
    },
    onRefresh(){
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad1();
      /*if(this.list.length<this.total){
        this.loading = true;
        this.params.page++;
        this.onLoad1();
      }else{
        this.loading = false;
        this.refreshing = false;
        this.finished = true;
      }*/

    },
    getOnLoadList(){
      this.loading = true;
      return new Promise(resolve => {
        this.$http({
          url: "/zuul/cultural/staffVacci",
          method: "get",
          params: this.params
        }).then(res=>{
          resolve(res);

        }).catch(e=>{
          this.error = true;
        }).finally(e=>{
          this.loading = false;
        })
      })
    },
    downLoad(){
      if(localStorage.getItem("wljptuser")){
        let url = '/zuul/cultural/staffVacci/export?createBy=' + localStorage.getItem("wljptuser");
        window.open(url,"blank")
      }else{
        Notify({ type: 'warning', message: '先进行用户认证！' });
      }

      // let url = 'http://localhost:8362/staffVacci/export?createBy=' + localStorage.getItem("wljptuser");

    },
    sendFile(file){
      console.log(file,"file")
      console.log(localStorage.getItem("wljptuser"))
      let user = localStorage.getItem("wljptuser")?localStorage.getItem("wljptuser"):"obbSB0gg0yyfBDfPZ80qtsGcsur8";
      let formData = new FormData();
      this.uploadloading = true;
      formData.append("file",file.file);
      this.$http({
        url: "/zuul/cultural/staffVacci/import?createBy=" + user,
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{

        if(res.data.success){
          Notify({ type: 'success', message: res.data.message });
          this.getList();

        }else{
          Notify({ type: 'warning', message: res.statusText });
        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(e=>{
        this.uploadloading = false;
      })
    },
    report(){
      this.reportPopup = true;
      this.recordData = {};
    },
    closeReport(){
      this.params.page = 0;
      this.getList();
      this.reportPopup = false;
    }
  },
  mounted(){
    console.log("tagIds=" + localStorage.getItem("wljpttagIds"))
    if(localStorage.getItem("wljptuser")){
      this.params = {
        size: this.params.size,
        page: 0,
        createBy: localStorage.getItem("wljptuser")
      }
      this.getList();
    }

  }
}
</script>

<style scoped lang="less">
.staff-list /deep/.van-tabs__nav--card .van-tab{
  color: #349DFF;
  border-right: 1px solid #349DFF;
}
.staff-list /deep/.van-tabs__nav--card{
  border: 1px solid #349DFF;
  border-right: none;
}
.staff-list /deep/.van-tabs__nav--card .van-tab.van-tab--active{
  color:white;
  background: #349DFF;
}
.staff-list /deep/.van-tabs__nav--card{
  margin: 0px;
}
.staff-list /deep/.van-uploader__input-wrapper{
  width: calc(50vw);
}
.staff-list{
  color: #515154;
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: left;
    line-height: 45px;
    position: relative;
    padding: 0px 0px 0px 25px;
    margin: 0px;
    background: #f7f8fa;
    //background: #DFE0DF;
    .van-icon{
      position: absolute;
      left: 0px;
      top: 8px;
      font-weight: normal;
      color: #969799;
    }
  }
  .align-center{
    text-align: center;
  }
  .list-btn{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: calc(100vw);
  }

  .staff-add{
    position: fixed;
    left: 10px;
    bottom: 60px;
    z-index: 9;
    cursor: pointer;
    background: white;
    border-radius: 50%;
  }
}
.des{
  padding: 10px 20px;
  color: rgba(69, 90, 100, 0.6);
  background: #f7f8fa;
}
.check-list{
  padding: 10px 20px 2px;
  font-size: 0.8rem;
  border-bottom: 1px solid #ebedf0;;
  >div{
    display: inline-block;
  }
  .item-choose{
    position: relative;
    left: -10px;
    width: 50px;
    vertical-align: middle;
  }
  .item-index{
    width: 30px;
    position: relative;
    //top: -25px;
    vertical-align: middle;
  }
  .item-check{
    width: 100px;
    line-height: 1.2rem;
    vertical-align: middle;
    .item-user{
      padding: 5px 0px 2px 0px;
      .van-icon{
        position: relative;
        top: 2px;
        margin-right: 2px;
      }
    }
  }
  .item-btn{
    width: 60px;
    text-align:right;
    position: relative;
    .item-click{
      position: absolute;
      top: 2px;
      right: -20px;
      width: 30px;
      font-size: 1rem;
      color: #999;
    }
  }
  .item-check-res,.item-rectify{
    padding: 0 2px;
    vertical-align: middle;
    word-break: break-word;
    width: calc(100vw - 290px);
  }
  .item-check-res{
    position: relative;
    padding-left: 10px;

    //color: green;
    //cursor: pointer;
  }
  .item-info,.item-sex,item-tip{
    //width: calc(100vw - 250px);
    width: calc(100vw - 320px);
    position: relative;
    left: -10px;
    vertical-align: middle;
    text-align: left;
  }

  .item-sex{
    width: 50px;
  }

  .item-tip{
    width: 80px;
  }

}
</style>
